import React, { useEffect, useState } from 'react'
import {
  Modal,
  Radio,
  Select,
  DatePicker,
  Space,
  TimePicker,
  Form,
  Spin,
  Alert
} from 'antd'
import { PrimaryButton, SecondaryButton } from 'components/common/Buttons'

import { useLocation } from 'react-router-dom'
import moment from 'moment'
import axios from 'axios'
import { handleError } from 'utils/handleApiResponse'

const ModalRegistroMovimientos = ({
  modalRegistro,
  setModalRegistro,
  setModalInfo,
  setShowModal,
  setErrors,
  setFetchConfig,
  fetchConfig,
  isMovement,
  setIsMovement
}) => {
  const [form] = Form.useForm()
  const [optionIngresos, setOptionIngresos] = useState(1)
  const [area, setArea] = useState([])
  const [areaSelected, setareaSelected] = useState('')
  const [areaActual, setareaActual] = useState('')
  const [sector, setSector] = useState(false)
  const [porteria, setPorteria] = useState(false)
  const [plants, setPlants] = useState(false)
  const [movementsWithOutPorteria, setmovementsWithOutPorteria] = useState([])
  const [movementsWithOutSector, setmovementsWithOutSector] = useState([])
  const [movementsWithOutPlant, setmovementsWithOutPlant] = useState([])
  const [sectorsPermission, setsectorsPermission] = useState([])
  const [porteriasPermission, setporteriasPermission] = useState([])
  const [plantsPermission, setplantsPermission] = useState([])
  const [isLodingSubmit, setisLoadingSubmit] = useState(false)
  const [isLoadingSpecies, setisLoadingSpecies] = useState(false)
  const [isLoadingPermission, setisLoadingPermissions] = useState(false)
  const [haveMovementsActive, sethaveMovementsActive] = useState(false)
  const [isLoadingMovementWithOut, setisloadingMovementWithOut] =
    useState(false)
  const [isLoadInfo, setisLoadInfo] = useState(false)
  const [fIngreso, setfIngreso] = useState(null)
  const [hIngreso, sethIngreso] = useState(null)
  const [fLastMov, setfLasMov] = useState(null)
  const [lastMovementActiveIsPorteria, setlastMovementActiveIsPorteria] =
    useState(false)
  const [haveLastMovementFinish, sethaveLastMovementFinish] = useState(false)

  const [optDisabled, setoptDisabled] = useState([])

  const { state } = useLocation()
  const { identificador, type_identificador } = state
  const format = 'HH:mm'
  const [showAlert, setShowAlert] = useState(false)
  const rules = [
    {
      required: true,
      message: 'Campo requerido'
    }
  ]

  const getSpecies = async () => {
    setisLoadingSpecies(true)
    const urlMovementsSpecieDataMaster = `${process.env.REACT_APP_BASE_URL_MOVEMENTS}/movements/data-master/species`
    await axios
      .get(urlMovementsSpecieDataMaster)
      .then((response) => {
        setArea(response.data)
      })
      .catch((error) => {})
      .finally(() => {
        setisLoadingSpecies(false)
      })
  }

  const getMovementsPending = async () => {
    setisLoadInfo(true)
    const urlMovementsActives = `${process.env.REACT_APP_BASE_URL_MOVEMENTS}/movements-user/movements-actives?identificador=${identificador}&type_identificador=${type_identificador}`
    await axios
      .get(urlMovementsActives)
      .then((response) => {
        if (response.data.hasMovementsActive) {
          sethaveMovementsActive(true)
          setareaSelected(response.data.lastMovement.area)
          if (response.data.lastMovementIsPorteria) {
            setlastMovementActiveIsPorteria(true)
            const sectorFormat = response.data?.sectors.map((sector) => ({
              label: sector.label,
              value: sector.value,
              status: sector.status,
              sector: sector.label
            }))

            setsectorsPermission(sectorFormat)

            setfLasMov(response.data.lastMovement.entryAt)
            // setareaSelected(response.data.lastMovement.area);
          }
        } else {
          if (response.data.haveLastMovementFinish) {
            sethaveLastMovementFinish(true)
            setfLasMov(response.data.lastmovementFinish.exitAt)
          }
        }
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        setisLoadInfo(false)
      })
  }

  const onChangeIngresos = (e) => {
    setOptionIngresos(e.target.value)

    if (e.target.value === 1) {
      form.resetFields()
      getSpecies()
      setsectorsPermission([])
      setporteriasPermission([])
      setplantsPermission([])
      setareaActual('')
      setPlants(false)
    } else if (e.target.value === 2) {
      form.resetFields()
      getSpecies()
      setsectorsPermission([])
      setporteriasPermission([])
      setareaActual('')
      setplantsPermission([])
      setareaActual('')
      setPlants(false)
    } else {
      form.resetFields()
      getSpecies()
      setsectorsPermission([])
      setporteriasPermission([])
      setareaActual('')
      setplantsPermission([])
      setareaActual('')
      setPlants(false)
    }
  }

  const onChangeSector = (e) => {
    if (optionIngresos === 2) {
      const SectorSelected = movementsWithOutSector?.find((x) => x.id === e)
      const allDate = moment(Number(SectorSelected.entryAt)).format(
        'YYYY/MM/DD HH:mm:ss'
      )

      const splitDate = allDate.split(' ')
      setfIngreso(splitDate[0])
      sethIngreso(splitDate[1])
    }
    setSector(true)

    const selectedSector = sectorsPermission.find(
      (sector) => sector.value === e
    )

    form.setFieldsValue({ sector: selectedSector.label })
  }

  const onChangePorteria = (e) => {
    if (optionIngresos === 2) {
      const SectorSelected = movementsWithOutPorteria?.find((x) => x.id === e)
      const allDate = moment(Number(SectorSelected.entryAt)).format(
        'YYYY/MM/DD HH:mm:ss'
      )

      const splitDate = allDate.split(' ')
      setfIngreso(splitDate[0])
      sethIngreso(splitDate[1])
    }
    setPorteria(true)
  }

  const onChangePlants = (e) => {
    if (optionIngresos === 2) {
      const PlantSelected = movementsWithOutPlant?.find((x) => x.id === e)

      const allDate = moment(Number(PlantSelected.entryAt)).format(
        'YYYY/MM/DD HH:mm:ss'
      )

      const splitDate = allDate.split(' ')
      setfIngreso(splitDate[0])
      sethIngreso(splitDate[1])
    }
    setPlants(true)
  }

  const onChangeArea = async (e) => {
    setShowAlert(false)
    setareaActual(e)
    if (optionIngresos === 1) {
      form.setFieldsValue({ porteria: undefined, sector: undefined })
      setSector(false)
      setPorteria(false)

      if (!lastMovementActiveIsPorteria) {
        setisLoadingPermissions(true)
        console.log(e)
        if (e !== 'Planta Alimentos') {
          const urlMovementsPermissionsUser = `${process.env.REACT_APP_BASE_URL_MOVEMENTS}/movements-user/user-request-exception?type_identificador=${type_identificador}&identificador=${identificador}&specie=${e}`
          await axios
            .get(urlMovementsPermissionsUser)
            .then((response) => {
              const sectorFormat = response.data?.sectores.map((sector) => ({
                label: sector.label,
                value: sector.value,
                status: sector.status,
                sector: sector.label
              }))

              setsectorsPermission(sectorFormat)

              setporteriasPermission(response.data.porterias)
              setShowAlert(
                response.data?.porterias?.length === 0 ||
                  response.data?.sectores?.length === 0
              )
            })
            .catch((error) => {})
            .finally(() => {
              setisLoadingPermissions(false)
            })
        } else {
          console.log('entro aqui')
          const urlMovementPermissionsPlantsUser = `${process.env.REACT_APP_BASE_URL_MOVEMENTS}/movements-user/plants?type_identificador=${type_identificador}&identificador=${identificador}`
          await axios
            .get(urlMovementPermissionsPlantsUser)
            .then((response) => {
              setplantsPermission(response.data)
              setShowAlert(response.data?.length === 0)
            })
            .catch((error) => {})
            .finally(() => {
              setisLoadingPermissions(false)
            })
        }
      } else {
        getMovementsPending()
      }
    } else if (optionIngresos === 2) {
      setisloadingMovementWithOut(true)
      form.setFieldsValue({ porteria: undefined, sector: undefined })
      setSector(false)
      setPorteria(false)
      const urlMovementsWithOutExit = `${process.env.REACT_APP_BASE_URL_MOVEMENTS}/movements-user/find-pending-movement?type_identificador=${type_identificador}&identificador=${identificador}&specie=${e}`
      await axios
        .get(urlMovementsWithOutExit)
        .then((response) => {
          setmovementsWithOutPlant(response.data.planta)
          setmovementsWithOutSector(response.data.sector)
          setmovementsWithOutPorteria(response.data.porteria)
        })
        .catch((error) => {})
        .finally(() => {
          setisloadingMovementWithOut(false)
        })
    } else {
      form.setFieldsValue({ porteria: undefined, sector: undefined })
      setSector(false)
      setPorteria(false)

      if (!lastMovementActiveIsPorteria) {
        setisLoadingPermissions(true)
        if (e !== 'Planta Alimentos') {
          const urlMovementsPermissionsUser = `${process.env.REACT_APP_BASE_URL_MOVEMENTS}/movements-user/user-request-exception?type_identificador=${type_identificador}&identificador=${identificador}&specie=${e}`
          await axios
            .get(urlMovementsPermissionsUser)
            .then((response) => {
              setporteriasPermission(response.data.porterias)
              setShowAlert(
                response.data?.porterias?.length === 0 ||
                  response.data?.sectores?.length === 0
              )
            })
            .catch((error) => {})
            .finally(() => {
              setisLoadingPermissions(false)
            })
        } else {
          console.log('entro aqui')
          const urlMovementPermissionsPlantsUser = `${process.env.REACT_APP_BASE_URL_MOVEMENTS}/movements-user/plants?type_identificador=${type_identificador}&identificador=${identificador}`
          await axios
            .get(urlMovementPermissionsPlantsUser)
            .then((response) => {
              setplantsPermission(response.data)
              setShowAlert(response.data?.length === 0)
            })
            .catch((error) => {})
            .finally(() => {
              setisLoadingPermissions(false)
            })
        }
      } else {
        getMovementsPending()
      }
    }
  }

  const disableDateExit = (current) => {
    const fechaIngreso = moment(fIngreso).add(-1, 'day')

    const tooEarly = fechaIngreso && current.diff(fechaIngreso, 'days') <= 0
    const tooLate = current && current.isAfter(moment(), 'days')

    return !!tooEarly || !!tooLate
  }

  const disabledTimeExit = () => {
    const fechaIngreso = form.getFieldValue('fechaIngreso')
    const fechaActual = moment()
    const splitHora = hIngreso.split(':')
    const hora = splitHora[0]
    const minutos = splitHora[1]
    const currentMinute = moment().minute()
    const currentHour = moment().hour()

    if (fechaIngreso && fechaActual.isSame(fechaIngreso, 'date')) {
      const disabledHours = () => {
        const hours = []
        for (let i = currentHour + 1; i < 24; i++) {
          hours.push(i)
        }
        return hours
      }
      const disabledMinutes = () => {
        const minutes = []
        for (let i = currentMinute + 1; i < 60; i++) {
          minutes.push(i)
        }
        return minutes
      }
      return {
        disabledHours,
        disabledMinutes
      }
    } else if (fechaIngreso && moment(fIngreso).isSame(fechaIngreso, 'date')) {
      const disabledHours = () => {
        const hours = []
        for (let i = 0; i < Number(hora); i++) {
          hours.push(i)
        }
        return hours
      }
      const disabledMinutes = () => {
        const minutes = []
        for (let i = 0; i < Number(minutos) + 1; i++) {
          minutes.push(i)
        }
        return minutes
      }

      return {
        disabledHours,
        disabledMinutes
      }
    } else {
      return false
    }
  }

  const onChangeFecha = (value, dateString) => {
    const fecha = moment(dateString).format('YYYY/MM/DD')
    form.setFieldsValue({ fechaIngreso: fecha })
  }

  const onChangeHora = (value, dateString) => {
    form.setFieldsValue({ horaIngreso: dateString })
  }

  const onChangeFechaSalida = (value, dateString) => {
    const fecha = moment(dateString).format('YYYY/MM/DD')
    form.setFieldsValue({ fechaSalida: fecha })
  }

  const onChangeHoraSalida = (value, dateString) => {
    form.setFieldsValue({ horaSalids: dateString })
  }

  const cerrarIr = () => {
    form.resetFields()
    setsectorsPermission([])
    setporteriasPermission([])
    sethaveMovementsActive(false)
    setlastMovementActiveIsPorteria(false)
    setOptionIngresos(1)
    setareaSelected('')
    setModalRegistro(false)
    setareaActual('')
    setPlants(false)
    setShowAlert(false)
  }

  const disableTimeEntry = (current) => {
    const fechaIngreso = form.getFieldValue('fechaIngreso')
    const fechaActual = moment()
    const currentMinute = moment().minute()
    const currentHour = moment().hour()
    if (haveMovementsActive) {
      if (lastMovementActiveIsPorteria) {
        const fechaIngresoPorteria = moment(Number(fLastMov)).format(
          'YYYY/MM/DD HH:mm:ss'
        )
        const splitDate = fechaIngresoPorteria.split(' ')
        const fechaPorteria = moment(splitDate[0])
        const horaIngreso = splitDate[1]
        const splitHora = horaIngreso.split(':')
        const horaPorteria = splitHora[0]
        const minPorteria = splitHora[1]
        const currentMinute = moment().minute()
        const currentHour = moment().hour()

        if (fechaIngreso && fechaActual.isSame(fechaIngreso, 'date')) {
          const disabledHours = () => {
            const hours = []
            for (let i = currentHour + 1; i < 24; i++) {
              hours.push(i)
            }
            return hours
          }
          const disabledMinutes = () => {
            const minutes = []
            for (let i = currentMinute + 1; i < 60; i++) {
              minutes.push(i)
            }
            return minutes
          }
          const disabledSeconds = () => {
            const segundos = []
            for (let i = 0; i < Number(segundos) + 1; i += 1) segundos.push(i)
            return segundos
          }

          return {
            disabledHours,
            disabledMinutes,
            disabledSeconds
          }
        } else if (fechaIngreso && fechaPorteria.isSame(fechaIngreso, 'date')) {
          const disabledHours = () => {
            const hours = []
            for (let i = 0; i < Number(horaPorteria); i++) {
              hours.push(i)
            }
            return hours
          }
          const disabledMinutes = () => {
            const minutes = []
            for (let i = 0; i < Number(minPorteria) + 1; i++) {
              minutes.push(i)
            }
            return minutes
          }
          const disabledSeconds = () => {
            const segundos = []
            for (let i = 0; i < Number(segundos) + 1; i += 1) segundos.push(i)
            return segundos
          }

          return {
            disabledHours,
            disabledMinutes,
            disabledSeconds
          }
        } else {
          return false
        }
      }
    } else {
      if (haveLastMovementFinish) {
        const fechaIngresoFinalizado = moment(Number(fLastMov)).format(
          'YYYY/MM/DD HH:mm:ss'
        )

        const splitDate = fechaIngresoFinalizado.split(' ')
        const horaLasMov = splitDate[1]
        const dateLasMov = splitDate[0]
        const splitHora = horaLasMov.split(':')
        const hora = splitHora[0]
        const min = splitHora[1]

        if (fechaIngreso && fechaActual.isSame(fechaIngreso, 'date')) {
          const disabledHours = () => {
            const hours = []
            for (let i = currentHour + 1; i < 24; i++) {
              hours.push(i)
            }
            return hours
          }
          const disabledMinutes = () => {
            const minutes = []
            for (let i = currentMinute + 1; i < 60; i++) {
              minutes.push(i)
            }
            return minutes
          }
          return {
            disabledHours,
            disabledMinutes
          }
        } else if (
          fechaIngreso &&
          moment(dateLasMov).isSame(fechaIngreso, 'date')
        ) {
          const disabledHours = () => {
            const hours = []
            for (let i = 0; i < Number(hora); i++) {
              hours.push(i)
            }
            return hours
          }
          const disabledMinutes = () => {
            const minutes = []
            for (let i = 0; i < Number(min) + 1; i++) {
              minutes.push(i)
            }
            return minutes
          }

          return {
            disabledHours,
            disabledMinutes
          }
        } else {
          return false
        }
      } else {
        if (fechaIngreso && fechaActual.isSame(fechaIngreso, 'date')) {
          const disabledHours = () => {
            const hours = []
            for (let i = currentHour + 1; i < 24; i++) {
              hours.push(i)
            }
            return hours
          }
          const disabledMinutes = () => {
            const minutes = []
            for (let i = currentMinute + 1; i < 60; i++) {
              minutes.push(i)
            }
            return minutes
          }
          return {
            disabledHours,
            disabledMinutes
          }
        } else {
          return false
        }
      }
    }
  }

  const disableDateEntry = (current) => {
    const allDate = moment(Number(fLastMov)).format('YYYY/MM/DD HH:mm:ss')
    const splitDate = allDate.split(' ')
    const fechaIngreso = moment(splitDate[0]).add(-1, 'day')

    const tooEarly = fechaIngreso && current.diff(fechaIngreso, 'days') <= 0
    const tooLate = current && current.isAfter(moment(), 'days')
    return !!tooEarly || !!tooLate
  }

  const disabledTime = () => {
    const horaingreso = form.getFieldValue('horaIngreso')
    const horasalida = form.getFieldValue('horaSalids')
    const splitHora = horaingreso.split(':')
    const hora = splitHora[0]
    const minutos = splitHora[1]
    const segundos = splitHora[2]

    const disabledHours = () => {
      const hours = []
      for (let i = 0; i < Number(hora); i += 1) hours.push(i)
      return hours
    }

    const disabledMinutes = () => {
      const minutes = []

      for (let i = 0; i < Number(minutos) + 1; i += 1) minutes.push(i)

      return minutes
    }

    return {
      disabledHours,
      disabledMinutes
    }
  }

  const layout = () => {
    if (optionIngresos === 1) {
      if (isLoadInfo) {
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Spin />
          </div>
        )
      }
      return (
        <Form
          form={form}
          layout='vertical'
          style={{
            marginTop: '10px'
          }}
          onFinish={handleSubmit}
        >
          <Form.Item
            className='modal-registros__labelModal'
            label='Seleccionar área'
            name='area'
            rules={rules}
          >
            <Select
              className='modal-registros__select'
              loading={isLoadingSpecies}
              disabled={isLoadingSpecies}
              onChange={(value) => onChangeArea(value)}
            >
              {area?.map((ar) => {
                return (
                  <Select.Option
                    value={ar.name_specie}
                    disabled={
                      areaSelected.toLowerCase() &&
                      ar.name_specie.toLowerCase() !==
                        areaSelected.toLowerCase()
                        ? true
                        : false
                    }
                    key={ar.id}
                  >
                    {ar.name_specie}
                  </Select.Option>
                )
              })}
            </Select>
          </Form.Item>
          {isLoadingPermission ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Spin size='large' />
            </div>
          ) : (
            <>
              {areaActual === 'Planta Alimentos' ? (
                <>
                  {plantsPermission.length === 0 && showAlert && (
                    <Alert
                      message={`Usuario no cuenta con permisos para Planta de alimentos`}
                      type='info'
                      showIcon
                    />
                  )}
                  <Form.Item
                    className='modal-registros__labelModal'
                    label='Planta Alimentos'
                    name='planta'
                  >
                    <Select
                      loading={isLoadingPermission}
                      disabled={
                        isLoadingPermission || plantsPermission.length === 0
                      }
                      onChange={onChangePlants}
                    >
                      {plantsPermission?.map((plantPerm, i) => {
                        return (
                          <Select.Option
                            value={plantPerm.plant}
                            key={plantPerm.value}
                          >
                            {plantPerm.plant}
                          </Select.Option>
                        )
                      })}
                    </Select>
                  </Form.Item>
                </>
              ) : (
                <>
                  {porteriasPermission.length === 0 &&
                    sectorsPermission.length === 0 &&
                    showAlert && (
                      <Alert
                        message={`Usuario no cuenta con permisos para ${areaActual}`}
                        type='info'
                        showIcon
                      />
                    )}
                  <Form.Item
                    className='modal-registros__labelModal'
                    label='Porteria'
                    name='porteria'
                  >
                    <Select
                      loading={isLoadingPermission}
                      disabled={
                        isLoadingPermission ||
                        porteriasPermission.length === 0 ||
                        sector
                      }
                      onChange={onChangePorteria}
                    >
                      {porteriasPermission?.map((portPerm, i) => {
                        return (
                          <Select.Option
                            value={portPerm.name}
                            key={portPerm.id}
                          >
                            {portPerm.name}
                          </Select.Option>
                        )
                      })}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    className='modal-registros__labelModal'
                    label='Sector'
                    name='sector'
                  >
                    <Select
                      showSearch
                      loading={isLoadingPermission}
                      disabled={
                        isLoadingPermission ||
                        sectorsPermission.length === 0 ||
                        porteria
                      }
                      optionFilterProp='children'
                      filterOption={(input, option) =>
                        (option?.label ?? '').includes(input)
                      }
                      onChange={onChangeSector}
                      options={sectorsPermission}
                    />
                    {/* {sectorsPermission?.map((secPerm, i) => {
                                          return (
                                            <Select.Option value={secPerm.label} key={i}>
                                              {secPerm.label}
                                            </Select.Option>
                                          )
                                        })}
                      
                      </Select> */}
                  </Form.Item>
                </>
              )}

              <div
                style={{
                  display: 'flex',
                  gap: '15px'
                }}
              >
                <Space
                  className='modal-registros__contenedor-fecha'
                  direction='vertical'
                >
                  <Form.Item
                    className='modal-registros__labelModal'
                    label='Fecha'
                    name='fecha'
                    rules={porteria || sector || plants ? rules : null}
                  >
                    <DatePicker
                      className='modal-registros__fecha'
                      onChange={onChangeFecha}
                      disabled={porteria || sector || plants ? false : true}
                      disabledDate={disableDateEntry}
                    />
                  </Form.Item>
                </Space>
                <Space className='modal-registros__contenedor-hora'>
                  <Form.Item
                    className='modal-registros__labelModal'
                    label='Hora'
                    name='hora'
                    rules={porteria || sector || plants ? rules : null}
                  >
                    <TimePicker
                      className='modal-registros__hora'
                      onChange={onChangeHora}
                      disabled={porteria || sector || plants ? false : true}
                      disabledTime={disableTimeEntry}
                      format={format}
                    />
                  </Form.Item>
                </Space>
              </div>
            </>
          )}
          <div
            style={{
              display: 'flex',
              justifyItems: 'center',
              marginTop: '15px',
              columnGap: '15px'
            }}
          >
            <div className='modal-registros__button-secundary'>
              <SecondaryButton onButtonClick={cerrarIr}>
                Cancelar
              </SecondaryButton>
            </div>
            <div className='modal-registros__button-primary'>
              <PrimaryButton type='primary' isLoading={isLodingSubmit}>
                Consultar
              </PrimaryButton>
            </div>
          </div>
        </Form>
      )
    } else if (optionIngresos === 2) {
      if (isLoadInfo) {
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Spin />
          </div>
        )
      }
      return (
        <Form
          form={form}
          layout='vertical'
          style={{
            marginTop: '10px'
          }}
          onFinish={handleSubmit}
        >
          <Form.Item
            className='modal-registros__labelModal'
            label='Seleccionar área'
            name='area'
            rules={rules}
          >
            <Select
              className='modal-registros__select'
              loading={isLoadingSpecies}
              disabled={isLoadingSpecies}
              onChange={(value) => onChangeArea(value)}
            >
              {area?.map((ar) => {
                return (
                  <Select.Option
                    value={ar.name_specie}
                    disabled={
                      areaSelected.toLowerCase() &&
                      ar.name_specie.toLowerCase() !==
                        areaSelected.toLowerCase()
                        ? true
                        : false
                    }
                    key={ar.id}
                  >
                    {ar.name_specie}
                  </Select.Option>
                )
              })}
            </Select>
          </Form.Item>
          {isLoadingPermission ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Spin size='large' />
            </div>
          ) : (
            <>
              {(plantsPermission.length === 0 ||
                (porteriasPermission.length === 0 &&
                  sectorsPermission.length === 0)) &&
                showAlert && (
                  <Alert
                    message={`Usuario no cuenta con permisos para ${areaActual}`}
                    type='info'
                    showIcon
                  />
                )}
              {areaActual === 'Planta Alimentos' ? (
                <Form.Item
                  className='modal-registros__labelModal'
                  label='Planta Alimentos'
                  name='planta'
                >
                  <Select
                    loading={isLoadingMovementWithOut}
                    disabled={
                      isLoadingMovementWithOut ||
                      movementsWithOutPlant.length === 0
                    }
                    onChange={onChangePlants}
                  >
                    {movementsWithOutPlant?.map((MovWOutPla, i) => {
                      console.log(MovWOutPla)
                      return (
                        <Select.Option
                          value={MovWOutPla.id}
                          key={MovWOutPla.id}
                        >
                          {MovWOutPla.sector}
                        </Select.Option>
                      )
                    })}
                  </Select>
                </Form.Item>
              ) : (
                <>
                  <Form.Item
                    className='modal-registros__labelModal'
                    label='Porteria'
                    name='porteria'
                  >
                    <Select
                      loading={isLoadingMovementWithOut}
                      disabled={
                        isLoadingMovementWithOut ||
                        movementsWithOutPorteria.length === 0 ||
                        sector
                      }
                      onChange={onChangePorteria}
                    >
                      {movementsWithOutPorteria?.map((MovWOutPer, i) => {
                        return (
                          <Select.Option
                            value={MovWOutPer.id}
                            key={MovWOutPer.id}
                          >
                            {MovWOutPer.sector}
                          </Select.Option>
                        )
                      })}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    className='modal-registros__labelModal'
                    label='Sector'
                    name='sector'
                  >
                    <Select
                      loading={isLoadingMovementWithOut}
                      disabled={
                        isLoadingMovementWithOut ||
                        movementsWithOutSector.length === 0 ||
                        porteria
                      }
                      onChange={onChangeSector}
                    >
                      {movementsWithOutSector?.map((MovWOutSec, i) => {
                        return (
                          <Select.Option
                            value={MovWOutSec.id}
                            key={MovWOutSec.id}
                          >
                            {MovWOutSec.sector}
                          </Select.Option>
                        )
                      })}
                    </Select>
                  </Form.Item>
                </>
              )}
              <div
                style={{
                  display: 'flex',
                  gap: '15px'
                }}
              >
                <Space
                  className='modal-registros__contenedor-fecha'
                  direction='vertical'
                >
                  <Form.Item
                    className='modal-registros__labelModal'
                    label='Fecha'
                    name='fecha'
                    rules={porteria || sector || plants ? rules : null}
                  >
                    <DatePicker
                      className='modal-registros__fecha'
                      onChange={onChangeFecha}
                      disabled={porteria || sector || plants ? false : true}
                      disabledDate={disableDateExit}
                    />
                  </Form.Item>
                </Space>
                <Space className='modal-registros__contenedor-hora'>
                  <Form.Item
                    className='modal-registros__labelModal'
                    label='Hora'
                    name='hora'
                    rules={porteria || sector || plants ? rules : null}
                  >
                    <TimePicker
                      className='modal-registros__hora'
                      onChange={onChangeHora}
                      disabled={porteria || sector || plants ? false : true}
                      disabledTime={disabledTimeExit}
                      format={format}
                    />
                  </Form.Item>
                </Space>
              </div>
            </>
          )}

          <div
            style={{
              display: 'flex',
              justifyItems: 'center',
              marginTop: '15px',
              columnGap: '15px'
            }}
          >
            <div className='modal-registros__button-secundary'>
              <SecondaryButton onButtonClick={cerrarIr}>
                Cancelar
              </SecondaryButton>
            </div>
            <div className='modal-registros__button-primary'>
              <PrimaryButton type='primary' isLoading={isLodingSubmit}>
                Consultar
              </PrimaryButton>
            </div>
          </div>
        </Form>
      )
    } else if (optionIngresos === 3) {
      if (isLoadInfo) {
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Spin />
          </div>
        )
      }
      return (
        <Form
          form={form}
          layout='vertical'
          style={{
            marginTop: '10px'
          }}
          onFinish={handleSubmit}
        >
          <Form.Item
            className='modal-registros__labelModal'
            label='Seleccionar área'
            name='area'
            rules={rules}
          >
            <Select
              className='modal-registros__select'
              loading={isLoadingSpecies}
              disabled={isLoadingSpecies}
              onChange={(value) => onChangeArea(value)}
            >
              {area?.map((ar) => {
                return (
                  <Select.Option
                    value={ar.name_specie}
                    disabled={
                      areaSelected.toLowerCase() &&
                      ar.name_specie.toLowerCase() !==
                        areaSelected.toLowerCase()
                        ? true
                        : false
                    }
                    key={ar.id}
                  >
                    {ar.name_specie}
                  </Select.Option>
                )
              })}
            </Select>
          </Form.Item>
          {isLoadingPermission ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Spin size='large' />
            </div>
          ) : (
            <>
              {(plantsPermission.length === 0 ||
                (porteriasPermission.length === 0 &&
                  sectorsPermission.length === 0)) &&
                showAlert && (
                  <Alert
                    message={`Usuario no cuenta con permisos para ${areaActual}`}
                    type='info'
                    showIcon
                  />
                )}
              {areaActual === 'Planta Alimentos' ? (
                <Form.Item
                  className='modal-registros__labelModal'
                  label='Planta Alimentos'
                  name='planta'
                >
                  <Select
                    loading={isLoadingPermission}
                    disabled={
                      isLoadingPermission || plantsPermission.length === 0
                    }
                    onChange={onChangePlants}
                  >
                    {plantsPermission?.map((plantPerm, i) => {
                      return (
                        <Select.Option
                          value={plantPerm.plant}
                          key={plantPerm.value}
                        >
                          {plantPerm.plant}
                        </Select.Option>
                      )
                    })}
                  </Select>
                </Form.Item>
              ) : (
                <>
                  <Form.Item
                    className='modal-registros__labelModal'
                    label='Porteria'
                    name='porteria'
                  >
                    <Select
                      loading={isLoadingPermission}
                      disabled={
                        isLoadingPermission ||
                        porteriasPermission.length === 0 ||
                        sector
                      }
                      onChange={onChangePorteria}
                    >
                      {porteriasPermission?.map((portPerm, i) => {
                        return (
                          <Select.Option
                            value={portPerm.name}
                            key={portPerm.id}
                          >
                            {portPerm.name}
                          </Select.Option>
                        )
                      })}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    className='modal-registros__labelModal'
                    label='Sector'
                    name='sector'
                  >
                    <Select
                      showSearch
                      loading={isLoadingPermission}
                      disabled={
                        isLoadingPermission ||
                        sectorsPermission.length === 0 ||
                        porteria
                      }
                      onChange={onChangeSector}
                      optionFilterProp='children'
                      filterOption={(input, option) =>
                        (option?.label ?? '').includes(input)
                      }
                      options={sectorsPermission}
                    />
                  </Form.Item>
                </>
              )}

              <div
                style={{
                  display: 'flex',
                  gap: '15px'
                }}
              >
                <Space
                  className='modal-registros__contenedor-fecha'
                  direction='vertical'
                >
                  <Form.Item
                    className='modal-registros__labelModal'
                    label='Fecha Ingreso'
                    name='fechaEntry'
                    rules={porteria || sector || plants ? rules : null}
                  >
                    <DatePicker
                      className='modal-registros__fecha'
                      onChange={onChangeFecha}
                      disabled={porteria || sector || plants ? false : true}
                      disabledDate={disableDateEntry}
                    />
                  </Form.Item>
                </Space>
                <Space className='modal-registros__contenedor-hora'>
                  <Form.Item
                    className='modal-registros__labelModal'
                    label='Hora Ingreso'
                    name='horaEntry'
                    rules={porteria || sector || plants ? rules : null}
                  >
                    <TimePicker
                      className='modal-registros__hora'
                      onChange={onChangeHora}
                      disabled={porteria || sector || plants ? false : true}
                      disabledTime={disableTimeEntry}
                      format={format}
                    />
                  </Form.Item>
                </Space>
              </div>
              <div
                style={{
                  display: 'flex',
                  gap: '15px'
                }}
              >
                <Space
                  className='modal-registros__contenedor-fecha'
                  direction='vertical'
                >
                  <Form.Item
                    className='modal-registros__labelModal'
                    label='Fecha Salida'
                    name='fechaExit'
                    rules={porteria || sector || plants ? rules : null}
                  >
                    <DatePicker
                      className='modal-registros__fecha'
                      onChange={onChangeFechaSalida}
                      disabled={porteria || sector || plants ? false : true}
                      disabledDate={disableDateEntry}
                    />
                  </Form.Item>
                </Space>
                <Space className='modal-registros__contenedor-hora'>
                  <Form.Item
                    className='modal-registros__labelModal'
                    label='Hora Salida'
                    name='horaExit'
                    rules={porteria || sector ? rules : null}
                  >
                    <TimePicker
                      className='modal-registros__hora'
                      onChange={onChangeHoraSalida}
                      disabled={porteria || sector || plants ? false : true}
                      disabledTime={disabledTime}
                      format={format}
                    />
                  </Form.Item>
                </Space>
              </div>
            </>
          )}

          <div
            style={{
              display: 'flex',
              justifyItems: 'center',
              marginTop: '15px',
              columnGap: '15px'
            }}
          >
            <div className='modal-registros__button-secundary'>
              <SecondaryButton onButtonClick={cerrarIr}>
                Cancelar
              </SecondaryButton>
            </div>
            <div className='modal-registros__button-primary'>
              <PrimaryButton type='primary' isLoading={isLodingSubmit}>
                Consultar
              </PrimaryButton>
            </div>
          </div>
        </Form>
      )
    }
  }

  const getSector = (sector) => {
    const sectorSelected = sector.split('/')
    return sectorSelected[2]
  }

  const handleSubmit = async (values) => {
    setisLoadingSubmit(true)
    let body = {}
    let url = {}
    let id
    let instalaciones = {}

    if (optionIngresos === 1) {
      const formValues = form.getFieldsValue(true)
      url = `${process.env.REACT_APP_BASE_URL_MOVEMENTS}/movements-user/register-entry`
      console.log(formValues)
      if (formValues.sector !== undefined) {
        instalaciones = {
          sector: getSector(formValues.sector),
          porteria: null,
          planta: null
        }
      } else if (formValues.porteria !== undefined) {
        instalaciones = {
          sector: formValues.porteria,
          porteria: null,
          planta: null
        }
      } else {
        instalaciones = {
          sector: null,
          porteria: null,
          planta: formValues.planta
        }
      }

      body = {
        rut: identificador,
        area: formValues.area,
        porteria: null,
        ...instalaciones,
        fecha: formValues.fechaIngreso,
        hora: formValues.horaIngreso,
        type_mark: 1,
        type_identificador
      }
    } else if (optionIngresos === 2) {
      const formValues = form.getFieldsValue(true)

      url = `${process.env.REACT_APP_BASE_URL_MOVEMENTS}/movements-user/register-exit`

      if (values.sector) {
        id = values.sector
      } else if (values.porteria) {
        id = values.porteria
      } else {
        id = values.planta
      }

      body = {
        id: id,
        date: formValues.fechaIngreso,
        hour: formValues.horaIngreso
      }
    } else {
      const formValues = form.getFieldsValue(true)
      url = `${process.env.REACT_APP_BASE_URL_MOVEMENTS}/movements-user/register-entry`
      if (formValues.sector !== undefined) {
        instalaciones = {
          sector: getSector(formValues.sector),
          porteria: null,
          planta: null
        }
      } else if (formValues.porteria !== undefined) {
        instalaciones = {
          sector: formValues.porteria,
          porteria: null,
          planta: null
        }
      } else {
        instalaciones = {
          sector: null,
          porteria: null,
          planta: formValues.planta
        }
      }

      body = {
        rut: identificador,
        area: formValues.area,
        porteria: null,
        ...instalaciones,
        device: 'App Agro',
        fecha: formValues.fechaIngreso,
        hora: formValues.horaIngreso,
        date_exit: formValues.fechaSalida,
        hour_exit: formValues.horaSalids,
        type_mark: 3,
        type_identificador
      }
    }

    await axios
      .post(url, body)
      .then((response) => {
        setModalRegistro(false)
        form.resetFields()
        setsectorsPermission([])
        setporteriasPermission([])
        sethaveMovementsActive(false)
        setlastMovementActiveIsPorteria(false)
        setOptionIngresos(1)
        setareaSelected('')
        setFetchConfig({
          ...fetchConfig,
          offset: 1,
          limit: 10
        })
      })
      .catch((error) => {
        setModalInfo({
          type: 'error',
          title: 'Salida no registrada',
          message: error
        })
        setErrors(handleError(error))
        setareaSelected('')
        setFetchConfig({
          ...fetchConfig,
          offset: 1,
          limit: 10
        })
      })
      .finally(() => {
        setisLoadingSubmit(false)
        setIsMovement(!isMovement)
      })
  }

  useEffect(() => {
    if (modalRegistro) {
      getSpecies()
      getMovementsPending()
      form.resetFields()
      setSector(false)
      setPorteria(false)
      setareaActual('')
    }
  }, [modalRegistro])

  useEffect(() => {
    if (haveMovementsActive && !lastMovementActiveIsPorteria) {
      setOptionIngresos(2)
      getSpecies()
    }
  }, [haveMovementsActive])

  useEffect(() => {
    if (!haveMovementsActive) {
      setoptDisabled([2])
      return
    }

    if (!lastMovementActiveIsPorteria) {
      setoptDisabled([1, 3])
      return
    }

    setoptDisabled([])
  }, [haveMovementsActive])

  return (
    <>
      <div className='modal-registros__container'>
        <Modal
          width={819}
          centered
          open={modalRegistro}
          footer={false}
          maskClosable={false}
          closable={false}
        >
          <p className='modal-registros__textModal'>Registrar movimiento</p>
          {!isLoadInfo && (
            <Radio.Group onChange={onChangeIngresos} value={optionIngresos}>
              <Radio
                className='modal-registros__radio'
                value={1}
                disabled={optDisabled.includes(1)}
              >
                Ingreso
              </Radio>
              <Radio
                className='modal-registros__radio'
                value={2}
                disabled={optDisabled.includes(2)}
              >
                Salida
              </Radio>
            </Radio.Group>
          )}

          {layout()}
        </Modal>
      </div>
    </>
  )
}
export default ModalRegistroMovimientos
